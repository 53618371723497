<template>
  <div class="row discount mt-2">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Cari <br /> </label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type category name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> Mencari .. </em> </span>
            </div>
            <div class="col-md-auto">
              <router-link
                :to="{ path: `/category/add` }"
                class="btn btn-block btn-primary"
              >
                <i class="fa fa-plus"> </i>
                Tambah Category
              </router-link>
            </div>
          </div>
          <b-row class="justify-content-end">
            <div class="col-md-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-auto pb-2">
              Count :
              <strong class="pl-2">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </b-row>
          <b-table
            sticky-header="500px"
            responsive="sm"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            striped
            select-mode="single"
            hover
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>

            <template #cell(category_bisac)="row">
              {{ row.item.bisac ? row.item.bisac.name : '-' }}
            </template>

            <template #cell(name)="row">
              <router-link
                :to="{ path: 'category/edit/' + row.item.id }"
                class="link"
              >
                {{ row.item.name }}
              </router-link>
            </template>

            <template #cell(is_active)="row">
              <span
                v-if="row.item.is_active"
                class="btn badge badge-primary"
                @click="updateStatus(row.item.is_active, row.item.id)"
              >
                Active
              </span>
              <span
                v-else
                class="btn badge badge-danger"
                @click="updateStatus(row.item.is_active, row.item.id)"
                >Inactive</span
              >
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading...
              </div>
            </template>
          </b-table>
          <b-row class="justify-content-start">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'discount',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        { key: 'name', label: 'Category Name' },
        { key: 'slug', label: 'Slug' },
        { key: 'category_bisac', label: 'Category Bisac' },
        { key: 'is_active', label: 'Status' },
        {
          key: 'created',
          label: 'Created',
          formatter: function(value) {
            return moment(value).format('DD MMM YYYY');
          },
        },
        {
          key: 'modified',
          label: 'Updated',
          formatter: function(value) {
            return moment(value).format('DD MMM YYYY');
          },
        },
      ],
      message: null,
      typing: null,
      status: null,
      statusOptions: [
        { value: null, text: 'Status' },
        { value: 1, text: 'Active' },
        { value: 2, text: 'Inactive' },
      ],
    };
  },
  computed: {
    ...mapGetters('category', { items: 'category' }),
    ...mapState({
      isLoading: (state) => state.category.isLoading,
      isError: (state) => state.category.isError,
      totalRows: (state) => state.category.totalRows,
    }),
  },
  mounted() {
    this.actionGetCategory();
  },
  watch: {
    currentPage: function() {
      this.actionGetCategory();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Category | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('category', ['fetchAll', 'search', 'editStatus']),
    actionGetCategory() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        is_active: this.status,
      };

      this.fetchAll(payload);
    },
    debounceSearch(event) {
      this.message = null;
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchAll({
          name: event.target.value,
          page: 1,
          limit: this.perPage,
          is_active: this.status,
        });
      }, 600);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMM YY');
    },
    asyncFind(query) {
      let payload = {
        q: query,
        page: 1,
        limit: this.perPage,
      };
      if (this.status) payload.is_active = this.status;
      this.search(payload);
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    onStatusChange() {
      let payload = {
        is_active: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.message) payload.name = this.message;
      this.fetchAll(payload);
    },

    updateStatus(status, id) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will update status to ${status ? 'inactive' : 'active'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
      }).then((result) => {
        if (result.isConfirmed) {
          this.editStatus({ status, id })
            .then((res) => {
              if (res.status == 200) {
                this.$swal({
                  icon: 'success',
                  toast: true,
                  position: 'top-end',
                  title: 'Success',
                  timer: 10000,
                  text: 'Status has been successfully changed.',
                });
              }
              this.actionGetCategory();
            })
            .catch((err) => {
              if (err.response.status == 404) {
                this.$swal('Ops!', err.response.data.message, 'error');
              }
              if (err.response.status == 422) {
                err.response.data.errors
                  .reverse()
                  .map((e) => this.$swal('Ops!', e?.msg, 'error'));
              }
              if (err.response.status == 500) {
                this.$swal('Ops!', err.response.data.data.message, 'error');
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/table.css';
</style>
