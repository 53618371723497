var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row discount mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Cari "), _c('br')]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "type category name..."
    },
    on: {
      "input": _vm.debounceSearch
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" Mencari .. ")])]) : _vm._e()]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('router-link', {
    staticClass: "btn btn-block btn-primary",
    attrs: {
      "to": {
        path: "/category/add"
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Tambah Category ")])], 1)]), _c('b-row', {
    staticClass: "justify-content-end"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "id": "currency",
      "options": _vm.statusOptions,
      "required": ""
    },
    on: {
      "change": _vm.onStatusChange
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-auto pb-2"
  }, [_vm._v(" Count : "), _c('strong', {
    staticClass: "pl-2"
  }, [_vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.totalRows)) + " ")])])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "striped": "",
      "select-mode": "single",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(category_bisac)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.bisac ? row.item.bisac.name : '-') + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: 'category/edit/' + row.item.id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [row.item.is_active ? _c('span', {
          staticClass: "btn badge badge-primary",
          on: {
            "click": function ($event) {
              return _vm.updateStatus(row.item.is_active, row.item.id);
            }
          }
        }, [_vm._v(" Active ")]) : _c('span', {
          staticClass: "btn badge badge-danger",
          on: {
            "click": function ($event) {
              return _vm.updateStatus(row.item.is_active, row.item.id);
            }
          }
        }, [_vm._v("Inactive")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading... ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-row', {
    staticClass: "justify-content-start"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }